<template>
  <h1 align="center" style="font-family: sans-serif;font-weight: bold;">Vă rog, așteptați...</h1>
</template>

<script>
import { AUTO_LOGIN } from '@/api.js'

export default {
  created() {
    this.clearUser()

    const token = this.$route.params.pathMatch

    const error = msg => {
      this.$toastr.e(msg || 'Autorizarea a eșuat')
      this.logout()
    }

    AUTO_LOGIN(token).then(res => {
      if(res && res.meta?.HttpStatusMessage == 'OK' && this.isString(res.data?.token?.access_token)) {
        this.login(res.data.token.access_token)
      } else {
        error()
      }
    }).catch(error)
  }
}
</script>